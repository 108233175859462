import api from "../../api/api";
//axios.defaults.withCredentials = true;
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const state = {
    shipping: false,
    orders: {
        receiving: [],
        packing: [],
        request: [],
        picking: [],
        distribution: [],
        complete:[],
    },
};

const mutations = {
    update_shipping(state, payload) {
        state.shipping = payload
        //fetchOrdersByShipping
    },
    update_order(state, payload) {
        state.orders.receiving = [];
        state.orders.packing = [];
        state.orders.request = [];
        state.orders.picking = [];
        state.orders.distribution = [];
        state.orders.complete = [];
        if (payload[0]) {
            payload.forEach((e) => {
                if (e.workflow_stage == "receiving") {
                    state.orders.receiving.push(e);
                }
                if (e.workflow_stage == "packing") {
                    state.orders.packing.push(e);
                }
                if (e.workflow_stage == "request") {
                    state.orders.request.push(e);
                }
                if (e.workflow_stage == "picking") {
                    state.orders.picking.push(e);
                }
                if (e.workflow_stage == "distribution") {
                    state.orders.distribution.push(e);
                }
                if (e.workflow_stage == "complete") {
                    state.orders.complete.push(e);
                }
            });
        }
    },
};

const actions = {

    // FETCH ORDERS (WORKFLOW FUNCTIONS)
    async fetchOrdersByState(context,payload = 1) {
        await api.instance.post("/api/order/fetchOrdersByState",{'period':payload}).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },
    async fetchOrdersBySpecificState(context,payload) {
        await api.instance.post("/api/order/OrderBySpecificState",payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },
    
    async fetchOrdersReceiving(context,payload) {
        await api.instance.post("/api/order/fetchOrdersReceiving",payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },

    async fetchOrdersReceivingPeriod(context,payload) {
        await api.instance.post("/api/order/fetchOrdersReceiving",{'period':payload}).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },

    async fetchOrdersGroupToGroupPeriod(context,payload) {
        await api.instance.post("/api/order/fetchOrdersGroupToGroup",{'period':payload}).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },

    async fetchOrdersGroupToGroup(context,payload) {
        await api.instance.post("/api/order/fetchOrdersGroupToGroup",payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },

    async fetchOrdersPOS(context,payload) {
        await api.instance.post("/api/order/fetchOrdersPOS",payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },
    async fetchOrdersPOSPeriod(context,payload) {
        await api.instance.post("/api/order/fetchOrdersPOS",{'period':payload}).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_order", payload);
        });

    },


    async fetchOrdersByShipping(context) {
        let payload = {};
        await api.instance.get("/api/order/fetchOrdersByShipping").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_shipping", payload);
        });
    },
    async update(context, payload) {
        let path = payload.path;
        payload = payload.payload;
        await api.instance
            .put(
                "/api/" +
                path +
                "/" +
                context.rootState.Profile.all.profile.branch_id +
                "/" +
                payload.id,
                payload
            )
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = false;
                } else {
                    payload = res.data;
                }
                context.commit("update_" + path, payload);
            });
    },
};

const getters = {
    orders: (state) => state.orders,
    shipping: (state) => state.shipping,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
