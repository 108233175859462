import api from "../../api/api";
//axios.defaults.withCredentials = true;
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const state = {
    all: {},
    menu: [],
    layout: [],
    widgets: [],
};

const mutations = {
    UPDATE_MENU(state, payload) {
        state.menu = payload;
    },
    UPDATE_LAYOUT(state, payload) {
        try {
            state.layout = JSON.parse(payload["gjs-components"]);
        } catch (err) {
            state.layout = payload["gjs-components"];
        }
        state.widgets = payload.widgets;
    },
};

const actions = {
    async fetch_menu(context) {
        // fetch navigation for user level
        await api.instance.get("/api/navigation").then((res) => {
            context.commit("UPDATE_MENU", res.data);
        });
    },
    fetch_default_theme() {
        //fetch the branding aka color scheme, logo and branding
    },
    async fetch_pages(context, section) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance
            .get("/api/layout/" + section.section + "/" + section.item)
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = {context: false, widgets: false};
                } else {
                    payload = res.data[0];
                }
                context.commit("UPDATE_LAYOUT", payload);
            });
    },
};

const getters = {
    getMenu: (state) => state.menu,
    getLayout: (state) => state.layout,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
