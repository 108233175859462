<!-- CodeInputForm.vue -->
<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="headline">Enter Admin Code</v-card-title>
      <v-card-text>
        <v-text-field v-model="code" label="Admin Code" required></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="submitCode">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/api";

export default {
  data() {
    return {
      dialog: false,
      code: '',
      lastRequestConfig: null,
    };
  },
  methods: {
    openDialog(config) {
      this.dialog = true;
      this.lastRequestConfig = config;
    },
    async submitCode() {
      this.dialog = false;
      if (this.lastRequestConfig) {
        const separator = this.lastRequestConfig.url.includes('?') ? '&' : '?';
        this.lastRequestConfig.url = `${this.lastRequestConfig.url}${separator}admin_key=${this.code}`;

          try {
           const response = await api.instance(this.lastRequestConfig);
            // Handle the response as needed
           console.log(response);
         } catch (error) {
            // Handle the error as needed
           console.log(error);
         }
        }
    },
  },
};
</script>