import api from "../../api/api";

const state = {
  wishlist: [], // Initial state for the wishlist
};

const mutations = {
  SET_WISHLIST(state, wishlist) {
    state.wishlist = wishlist;
  },
  UPDATE_WISHLIST_LINE_ITEM(state, { itemId, quantity }) {
    const item = state.wishlist.find((item) => item.id === itemId);
    if (item) {
      item.quantity = quantity;
    }
  },
};

const actions = {
  fetchWishlist({ commit }) {
    return new Promise((resolve, reject) => {
      api.instance
        .get("/api/wishlists")
        .then((response) => {
          const wishlist = response.data;
          commit("SET_WISHLIST", wishlist);
          resolve(wishlist);
        })
        .catch((error) => {
          console.error("Failed to fetch wishlist:", error);
          reject(error);
        });
    });
  },
  createWishlist({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.instance
        .post("/api/wishlists", payload)
        .then((response) => {
          const wishlist = response.data;
          commit("SET_WISHLIST", wishlist);
          resolve(wishlist);
        })
        .catch((error) => {
          console.error("Failed to create wishlist:", error);
          reject(error);
        });
    });
  },
  updateWishlistLineItem({ commit }, { itemId, quantity }) {
    return new Promise((resolve, reject) => {
      api.instance
        .put(`/api/wishlist/${itemId}`, { quantity })
        .then((response) => {
          const updatedItem = response.data;
          commit("UPDATE_WISHLIST_LINE_ITEM", {
            itemId,
            quantity: updatedItem.quantity,
          });
          resolve(updatedItem);
        })
        .catch((error) => {
          console.error("Failed to update wishlist line item:", error);
          reject(error);
        });
    });
  },
  removeWishlistLineItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      api.instance
        .delete(`/api/wishlist/${itemId}`)
        .then(() => {
          commit("REMOVE_WISHLIST_LINE_ITEM", itemId);
          resolve();
        })
        .catch((error) => {
          console.error("Failed to remove wishlist line item:", error);
          reject(error);
        });
    });
  },
  deleteWishlist({ commit }) {
    return new Promise((resolve, reject) => {
      api.instance
        .post("/api/clear-list")
        .then(() => {
          commit("SET_WISHLIST", []);
          resolve();
        })
        .catch((error) => {
          console.error("Failed to delete wishlist:", error);
          reject(error);
        });
    });
  },
  clearWishlist({ commit }) {
    commit("SET_WISHLIST", []);
  },
};

const getters = {
  wishlist: (state) => state.wishlist,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
