import api from "../../api/api";

const state = {
    branches: [],
    monthlyOrders: [],
  };
  
  const mutations = {
    setBranches(state, branches) {
      state.branches = branches;
    },
    setMonthlyOrders(state, orders) {
      state.monthlyOrders = orders;
    },
  };
  
  const actions = {
    async fetchBranches({ commit }) {
      const response = await api.instance.get('/api/branch');
      commit('setBranches', response.data);
    },
    async fetchBranchOrders({ commit }, branchId) {
      const response = await api.instance.get(`/api/branch/${branchId}/orders`);
      commit('setMonthlyOrders', response.data);
    },
  };
  
  const getters = {
    branches: state => state.branches,
    monthlyOrders: state => state.monthlyOrders,
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };