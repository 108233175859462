import api
    from "../../api/api";
import Vue from "vue";

const initialState = () => {
    return {
        suppliers: [],
        pagination: {
            page: 0,
            rowsPerPage: 0,
        },
        totalItems: 0,
        shipment: {},
        shipments: [],
        ALLshipments: [],
        shipmentItem: {},
        shipmentItems: [],
        shipmentDocuments: [],
        shipmentDocument: {},
    }
}

const state = {
    initialState: initialState,
    suppliers: [],
    pagination: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null,
    },
    totalItems: null,
};

const mutations = {
    FETCH_SUPPLIERS(state, payload) {
        state.suppliers = payload.data.data;
        state.pagination = {
            current_page: payload.current_page,
            from: payload.from,
            last_page: payload.last_page,
            per_page: payload.per_page,
            to: payload.to,
            total: payload.total,
        };
        state.totalItems = payload.total;
    },
    UPDATE_SUPPLIERS(state, payload) {
        state.suppliers = payload.data;
    },
    CREATE_SUPPLIER(state, payload) {
        state.suppliers = payload.data.data;
        state.pagination = {
            current_page: payload.current_page,
            from: payload.from,
            last_page: payload.last_page,
            per_page: payload.per_page,
            to: payload.to,
            total: payload.total,
        };
        state.totalItems = payload.total;
    },
    UPDATE_SUPPLIER(state, payload) {
        state.suppliers = payload.data.data;
        state.pagination = {
            current_page: payload.current_page,
            from: payload.from,
            last_page: payload.last_page,
            per_page: payload.per_page,
            to: payload.to,
            total: payload.total,
        };
        state.totalItems = payload.total;
    },
    DELETE_SUPPLIER(state, payload) {
        state.suppliers = state.suppliers.filter(supplier => supplier.id !== payload)
    },
    UPDATE_SHIPMENT(state, payload) {
        state.shipment = payload;

        if (!state.shipments) {
            state.shipments = [payload];
        }

        const index = state.shipments.findIndex(shipment => shipment.id === payload.id);
        if (index !== -1) {
            state.shipments[index] = payload;
        } else {
            state.shipments.push(payload);
        }
        // state.shipments = payload;
    },
    UPDATE_SHIPMENTS(state, payload) {
        state.ALLshipments = payload;
        Vue.set(state.ALLshipments,  payload);
    },
};

const actions = {
    async searchSuppliers(context, payload) {
        await api.instance.post(`/api/suppliers/search`, payload)
            .then((res) => {
                context.commit("FETCH_SUPPLIERS", res);
            });
    },
    // async fetchInventories(context, id) {
    //     await api.instance.get(`/api/suppliers/${id}`)
    //         .then((res) => {
    //             context.commit("UPDATE_SUPPLIER", res);
    //         });
    // },
    async fetchSuppliers(context) {
        await api.instance.get(`/api/suppliers`)
            .then((res) => {
                context.commit("FETCH_SUPPLIERS", res);
            });
    },
    async fetchSupplierList(context) {
        await api.instance.get(`/api/suppliers/everything`)
            .then((res) => {
                context.commit("UPDATE_SUPPLIERS", res);
            });
    },
    async fetchPaginatedSuppliers(context, data) {
        let page_number = data.pagination.to;
        await api.instance.get(`/api/suppliers?page=${page_number}`)
            .then((res) => {
                context.commit("FETCH_SUPPLIERS", res);
            });
    },
    async createSupplier(context, data) {
        await api.instance.post("/api/suppliers", data)
            .then((res) => {
                context.commit("CREATE_SUPPLIER", res);
            });
    },
    async updateSupplier(context, data) {
        await api.instance.put(`/api/suppliers/${data.id}`, data)
            .then((res) => {
                context.commit("UPDATE_SUPPLIER", res);
            });
    },
    async deleteSupplier(context, id) {
        await api.instance.delete(`/api/suppliers/${id}`)
            .then(() => {
                context.commit("DELETE_SUPPLIER", id);
            });
    },
    async updateShipment(context,payload) {
        await api.instance.put(`/api/shipment/${payload.id}`,payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_SHIPMENT", payload);
            return payload;
        });
    },
    async createShipment(context,payload) {
        await api.instance.post("/api/shipment",payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_SHIPMENT", payload);
            return payload;
        });
    },
    async fetchShipments(context) {
        await api.instance.get(`/api/shipment`)
            .then((res) => {
                context.commit("UPDATE_SHIPMENTS", res.data);
                return res.data;
            });
    },

};

const getters = {
    suppliers: (state) => state.suppliers,
    pagination: (state) => state.pagination,
    totalItems: (state) => state.totalItems,
    ALLshipments: (state) => state.ALLshipments,
    shipments: (state) => state.shipments,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};