import api from "../../api/api";

//axios.defaults.withCredentials = true;
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// 1. `/api/cart/{organization_id}/{branch_id}/{{id}}`
// 2. `/api/stock/{organization_id}/{branch_id}/`
// 3. `/api/order/{organization_id}/{branch_id}/`
// 4. `/api/payment_processor/{organization_id}/{branch_id}/`
// 5. `/api/invoice/{organization_id}/{branch_id}/`
// 6. `/api/line_item/{organization_id}/{branch_id}/`

const state = {
    cart: {},
    scanned_cart: [],
    cart_type: "branch",
    scan_mode: false,
    scan_to_paste:false,
    oe_mode: false,
    in_stock_only: true,
    active_company: 0,
    active_company_low_stock:0,
    count_order_mode: false,
    stock: {},
    order: {},
    payment_processor: {},
    invoice: {},
    line_items: [],
    stocks: {},
    similar_stock: [],
    source_of_truth: [],
};

const mutations = {
    update_cart(state, payload) {
        state.cart = payload;
    },
    update_stock(state, payload) {
        state.stock = payload;
    },
    update_order(state, payload) {
        state.order = payload;
    },
    update_payment_processor(state, payload) {
        state.payment_processor = payload;
    },
    update_invoice(state, payload) {
        state.invoice = payload;
    },
    update_line_items(state, payload) {
        state.line_items = payload;
    },
    reset_cart(state) {
        state.line_items = [];
        state.source_of_truth = [];
    },
    reset_scan_count(state) {
        state.scanned_cart = [];
    },
    SCAN_TO_CART(state, payload) {
        //update to use lodash instead
        let found = false;
        if (typeof state.scanned_cart === "string") {
            state.scanned_cart = [];
        }
        let scan_reset = state.scanned_cart;
        state.scanned_cart = false;
        state.scanned_cart = scan_reset;
        state.scanned_cart.forEach((e) => {
            if (e.id == payload.id) {
                let newamount = parseInt(e.amount) + 1;
                e.amount = newamount;
                e.checkCount = newamount;
                found = true;
            }
        });
        if (found == false) {
            payload.amount = 1;
            payload.checkCount = 0;
            state.scanned_cart.push(payload);
        }
    },
    ADD_LINE_ITEM(state, payload) {
        //update to use lodash instead
        let found = false;
        if (typeof state.line_items === "string") {
            state.line_items = [];
        }
        state.line_items.forEach((e) => {
            //console.log(e);
            if (e.id == payload.id) {
                let newamount = parseInt(e.amount) + 1;
                e.amount = newamount;
                found = true;
            }
        });
        //console.log(found);
        if (found == false) {
            state.line_items.push(payload);
            state.source_of_truth.push(payload);
        }
    },
    update_lineitem_stock(state, payload) {
        state.line_items = payload;
    },
    remove_line_items(state, payload) {
        var index = state.line_items.indexOf(payload);
        if (index > -1) {
            state.line_items.splice(index, 1);
        }
        
        var truth_index = state.source_of_truth.indexOf(payload);
        if (truth_index > -1) {
            state.source_of_truth.splice(index, 1);
        }
    },
    clear_scan_count(state) {
        state.scanned_cart = [];
    },
    remove_scanned_item(state, payload) {
        var index = state.scanned_cart.indexOf(payload);
        if (index > -1) {
            state.scanned_cart.splice(index, 1);
        }
    },
    cart_type(state, payload) {
        state.cart_type = payload;
    },
    scan_mode(state, payload) {
        state.scan_mode = payload;
    },
    oe_mode(state, payload) {
        state.oe_mode = payload;
    },
    scan_to_paste(state, payload) {
        state.scan_to_paste = payload;
    },
    in_stock_only(state, payload) {
        state.in_stock_only = payload;
    },
    active_company(state, payload) {
        state.active_company = payload;
    },
    active_company_low_stock(state, payload) {
        state.active_company_low_stock = payload;
    },
    count_order_mode(state, payload) {
        state.count_order_mode = payload;
    },
    allStockHistory(state, payload){
        state.stocks = payload;
    },
    fetch_user_branch_stock_history(state, payload){
        state.stocks = payload;
    },
    fetch_by_inventory_and_branch(state, payload){
        state.similar_stock = payload;
    },
};

const actions = {
    async confirm_payment(contex, payload) {
        await api.instance.post("/api/order/confirm_payment", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
        });
    },
    async confirm_transfer(contex, payload) {
        await api.instance.post("/api/order/confirm_stock_transfer", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
        });
    },
    async add_funds_to_wallet(contex, payload) {
        await api.instance.post("/api/order/add_funds_to_wallet", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
        });
    },
    async issue_refund(contex, payload) {
        
        await api.instance.post("/api/refund/issue_refund", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
        });
    },
    async complete_packing(contex, payload) {
        await api.instance.post("/api/order/complete_packing", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
        });
    },
    async complete_receiving(contex, payload) {
        await api.instance.post("/api/order/complete_receiving", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
        });
    },
    async complete_distribution(contex, payload) {
        await api.instance.post("/api/order/complete_distribution", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
        });
    },
    async fetch_all_pos(context, path = "cart") {
        let payload = {};
        await api.instance.get("/api/" + path).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    async fetch_single(context, path = "cart", payload) {
        await api.instance.get("/api/" + path + "/" + payload.id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    async create_pos(context, path = "cart", payload) {
        await api.instance.post("/api/" + path + "/", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    async update_pos(context, path = "cart", payload) {
        await api.instance.put("/api/" + path + "/" + payload.id, payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    async delete_pos(context, path = "cart", payload) {
        await api.instance.delete("/api/" + path + "/" + payload.id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    async verifyStockLevels(context) {
        let payload = {};
        let active_company = context.rootState.Pos.active_company.id;
        await api.instance
            .post("/api/stock/verifyStockLevels", {line_items: context.state.line_items, branch_id: active_company})
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = false;
                } else {
                    payload = res.data;
                }
                context.commit("update_lineitem_stock", payload);
            });
    },
    async allStockHistory(context) {
        let payload = {};
        await api.instance
            .get("/api/stock_history/fetch_user_branch_stock_history")
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = false;
                } else {
                    payload = res.data;
                }
                context.commit("allStockHistory", payload);
            });
    },
    async fetch_user_branch_stock_history(context) {
        let payload = {};
        await api.instance
            .get("/api/stock_history/fetch_user_branch_stock_history")
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = false;
                } else {
                    payload = res.data;
                }
                context.commit("allStockHistory", payload);
            });
    },
    async search_branch_stock_history(context, payload) {
        const { entity, dates, partNumber, entityType, selectedTypes } = payload;
      
        const typeParams = selectedTypes.length > 0 ? `&types=${selectedTypes.join(',')}` : '';
      
        await api.instance
          .get(`/api/stock_history/search_branch_stock_history?entity=${entity}&dates=${dates}&partNumber=${partNumber}&entityType=${entityType}${typeParams}`)
          .then((res) => {
            let stockHistoryData;
            if (!res.data || res.data.length === 0) {
              stockHistoryData = false;
            } else {
              stockHistoryData = res.data;
            }
            context.commit("allStockHistory", stockHistoryData);
        });
    },
    async fetch_by_inventory_and_branch(context, payload) {
        try {
          const response = await api.instance.get(`/api/stock_history/fetch_by_inventory_and_branch?inventory_id=${payload.inventory_id}&branch_id=${payload.branch_id}`);
          if (!response.data || response.data.length === 0) {
            return false;
          } else {
            return response.data;
          }
        } catch (error) {
          // Handle error if needed
          console.error(error);
          return null;
        }
    },



    async fetch_import_price_histories(context, payload) {
        try {
          
          const response = await api.instance.get(`/api/fetch_import_price_histories?system_number=${payload.system_number}&branch_id=${payload.branch_id}`);
          if (!response.data || response.data.length === 0) {
            return false;
          } else {
            return response.data;
          }
        } catch (error) {
          // Handle error if needed
          console.error(error);
          return null;
        }
    },


    //  async fetch_import_price_histories(payload){

    //     await api.instance.post("/api/fetch_import_price_history",payload).then((res) =>{
        
    //     })
    //  },


    //  async complete_distribution(contex, payload) {
    //     await api.instance.post("/api/order/complete_distribution", payload).then((res) => {
    //         if (!res.data || res.data.length == 0) {
    //             payload = false;
    //         } else {
    //             payload = res.data;
    //         }
    //     });
    // },







    //the main ones we will delete the above for the new backend pattern
    addLineItem({commit}, payload) {
        commit("ADD_LINE_ITEM", payload);
    },
    scantocartbootup(context, e) {
        context.commit("SCAN_TO_CART", e);
    },
    reset_scan_count(context) {
        context.commit("reset_scan_count");
    },
    async scantocart(context, order) {
        let payload = {};
        await api.instance.get("/api/inventory/system_number/" + order).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data[0];
            }
            context.commit("SCAN_TO_CART", payload);
        });
    },
    clearCart({commit}) {
        commit("reset_cart");
    },
    removeLineItem({commit}, payload) {
        commit("remove_line_items", payload);
    },
    removeScannedItem({commit}, payload) {
        commit("remove_scanned_item", payload);
    },
    async createOrder({commit}, payload) {
        await api.instance.post("/api/order", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            commit("update_order", payload);
            commit("update_line_items", "");
        });
    },
    async logCount({commit}, payload) {
        await api.instance.post("/api/stock/scanToUpdate", payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            commit("clear_scan_count");
        });
    },
    cart_type({commit}, payload) {
        commit("cart_type", payload);
    },
    scan_to_paste({commit}, payload) {
        commit("scan_to_paste", payload);
    },
    scan_mode({commit}, payload) {
        commit("scan_mode", payload);
    },
    oe_mode({commit}, payload) {
        commit("oe_mode", payload);
    },
    in_stock_only({commit}, payload) {
        commit("in_stock_only", payload);
    },
    active_company({commit}, payload) {
        commit("active_company", payload);
    },
    active_company_low_stock({commit},payload){
        commit("active_company_low_stock",payload)
    },
    count_order_mode({commit}, payload) {
        commit("count_order_mode", payload);
    },
};

const getters = {
    cart: (state) => state.cart,
    stock: (state) => state.stock,
    order: (state) => state.order,
    payment_processor: (state) => state.payment_processor,
    invoice: (state) => state.invoice,
    line_items: (state) => state.line_items,
    cart_type: (state) => state.cart_type,
    scan_mode: (state) => state.scan_mode,
    oe_mode: (state) => state.oe_mode,
    scan_to_paste: (state) => state.scan_to_paste,
    active_company: (state) => state.active_company,
    
    active_company_low_stock: () => state.active_company_low_stock,
    in_stock_only: (state) => state.in_stock_only,
    scanned_cart: (state) => state.scanned_cart,
    count_order_mode: (state) => state.count_order_mode,
    allStockHistory: (state) => state.stocks,
    fetch_user_branch_stock_history: (state) => state.stocks,
    fetch_by_inventory_and_branch: (state) => state.stocks,
    source_of_truth: (state) => state.source_of_truth,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
