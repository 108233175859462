import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueExcelEditor from "vue-excel-editor";
import * as VueGoogleMaps from "vue2-google-maps";
import VueGeolocation from "vue-browser-geolocation";
import vueCountryRegionSelect from "vue-country-region-select";
import general_mixins from "./mixins/general_mixins";
import vueVimeoPlayer from 'vue-vimeo-player'
import vGoogleTranslate from "v-google-translate";
import JsonExcel from "vue-json-excel";
import VueExcelViewer from '@uublue/vue-excel-viewer';
import '@uublue/vue-excel-viewer/lib/vue-excel-viewer.css';
import "./assets/css/global.css";

Vue.use(VueExcelViewer)

Vue.use(vueVimeoPlayer)
Vue.mixin(general_mixins);
Vue.use(vueCountryRegionSelect);
Vue.use(vGoogleTranslate);
Vue.component("downloadExcel", JsonExcel);
Vue.use(require("vue-moment"));
Vue.use(VueGeolocation);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBiHOnqV7hpuzDQ-hSocpljaGAASKSNOOQ",
        autobindAllEvents: true,
    },
});
Vue.use(VueSkycons, {
    color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VueExcelEditor);

Vue.filter('commaSpace', function (value){
    if (!value) return ''
    //replace commas not followed by a space with a comma followed by a space
 
        if(typeof value === 'string') {
            return value.replace(/,(?![\s])/g, ', ')
        } else{
            let new_value = value.toString();
            return new_value.replace(/,(?![\s])/g, ', ')
        
        }
    
})

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
