//import axios from "axios";
//import { API_URL } from "../../api/constants";
import api from "../../api/api";

//import { API_URL } from "../../api/constants";
//import axios from "axios";
//axios.defaults.withCredentials = true;
//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// 1. `/api/warehouse/{organization_id}/{branch_id}/{{id}}`
// 2. `/api/stock/{organization_id}/{branch_id}/`
// 3. `/api/order/{organization_id}/{branch_id}/`
// 4. `/api/payment_processor/{organization_id}/{branch_id}/`
// 5. `/api/invoice/{organization_id}/{branch_id}/`
// 6. `/api/line_item/{organization_id}/{branch_id}/`

const state = {
    all: {
        cacheTriggers: {
            catalogue: false,
            workflow: false,
            users: false,
            organization: false,
            ui: false,
        },
        cart: {},
        stock: {},
        order: {},
        shift: {},
        payment_processor: {},
        invoice: {},
        line_items: {},
        organization: {},
        branch: {},
        inventory: {},
        catalogue: {},
        addresses: {},
        profiler: {},
        support: {},
        refund: {},
        theme: {},
        meta_key: {},
    },
    socket_status: false,
    socket_messages: [],
};

const mutations = {
    update_warehouse(state, payload) {
        state.all.warehouse = payload;
    },
    update_stock(state, payload) {
        state.all.stock = payload;
    },
    update_order(state, payload) {
        state.all.order = payload;
    },
    update_payment_processor(state, payload) {
        state.all.payment_processor = payload;
    },
    update_invoice(state, payload) {
        state.all.invoice = payload;
    },
    update_line_items(state, payload) {
        state.all.line_items = payload;
    },
    update_organization(state, payload) {
        state.all.organization = payload;
    },
    update_branch(state, payload) {
        state.all.branch = payload;
    },
    update_inventory(state, payload) {
        state.all.inventory = payload;
    },
    update_catalogue(state, payload) {
        state.all.catalogue = payload;
    },
    update_profiler(state, payload) {
        state.all.profiler = payload;
    },
    update_addresses(state, payload) {
        state.all.addresses = payload;
    },
    update_support(state, payload) {
        state.all.support = payload;
    },
    update_refund(state, payload) {
        state.all.refund = payload;
    },
    update_theme(state, payload) {
        state.all.theme = payload;
    },
    update_shift(state, payload) {
        state.all.shift = payload;
    },
    update_meta_key(state, payload) {
        state.all.meta_key = false;
        state.all.meta_key = payload;
    },
    socket_status(state, payload) {
        state.socket_status = payload;
    },
    socket_messages(state, payload) {
        state.socket_messages.push(payload);
    },
    SET_RT_WORKFLOW(state, payload) {
        state.all.cacheTriggers.workflow = payload
    },
    SET_RT_CATALOGUE(state, payload) {
        state.all.cacheTriggers.catalogue = payload
    },
    SET_RT_USERS(state, payload) {
        state.all.cacheTriggers.users = payload
    },
    SET_RT_UI(state, payload) {
        state.all.cacheTriggers.ui = payload
    },
    SET_RT_ORGANIZATION(state, payload) {
        state.all.cacheTriggers.organization = payload
    }
};

const actions = {
    async fetch_all(context, path = "warehouse") {
        let payload = {};
        await api.instance.get("/api/" + path).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    async fetch_single(context, payload) {
        let path = payload.path;
        payload = payload.payload;
        await api.instance.get("/api/" + path + "/" + payload.id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    async create(context, payload) {
        let path = payload.path;
        
        payload = payload.payload;
        await api.instance.post("/api/" + path, payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.dispatch("fetch_all", path);
            // context.dispatch("Catalogue/fetch_Catalogue", null, {root: true});
            return payload;
        });
    },
    async update(context, payload) {
        let path = payload.path;
        
        payload = payload.payload;
        return await api.instance.put("/api/" + path + "/" + payload.id, payload).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
            return payload;
        });
    },
    async delete(context, payload) {
        let path = payload.path;
        payload = payload.payload;
        await api.instance.delete("/api/" + path + "/" + payload.id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("update_" + path, payload);
        });
    },
    socket_status(context, payload) {
        context.commit("socket_status", payload);
    },
    run_cache_event_by_action(context, payload) {
        if (payload.action == 'cataloque' && context.state.all.cacheTriggers.catalogue == false) {
            context.dispatch("fetch_catalogue")
        }
        if (payload.action == 'workflow' && context.state.all.cacheTriggers.workflow == false) {
            context.dispatch("fetch_workflow")
        }
        if (payload.action == 'user' && context.state.all.cacheTriggers.user == false) {
            context.dispatch("fetch_user")
        }
        if (payload.action == 'organization' && context.state.all.cacheTriggers.organization == false) {
            context.dispatch("fetch_organization")
        }
        if (payload.action == 'ui' && context.state.all.cacheTriggers.ui == false) {
            context.dispatch("fetch_ui")
        }
    },
    fetch_catalogue(context) {
        context.dispatch("Catalogue/fetch_Catalogue", null, {root: true});
        context.dispatch("Catalogue/fetchCatalogueTypes", null, {root: true});
        context.dispatch("Catalogue/fetch_Inventory", null, {root: true});
    },
    fetch_user(context) {
        context.dispatch("Organization/fetchStaff", null, {root: true});
        context.dispatch("Profile/fetch_internal_staff", null, {root: true});
        context.dispatch("Profile/fetch_companies", null, {root: true});
        context.dispatch("Profile/fetch_customers", null, {root: true});
    },
    fetch_workflow(context) {
        context.dispatch("Organization/fetchStaff", null, {root: true});
        context.dispatch("Warehouse/fetchOrdersByState", null, {root: true});
    },

    fetch_ui(context) {
        context.dispatch("Organization/fetchStaff", null, {root: true});
    },
    fetch_organization(context) {
        context.dispatch("Organization/fetch_Organization", null, {root: true});
        context.dispatch("Organization/fetch_Branch", null, {root: true});
        context.dispatch("Organization/fetchBranchByType", "warehouse", {root: true});
        context.dispatch("Organization/fetchBranchByType", "supplier", {root: true});
        context.dispatch("Organization/fetchBranchByType", "branch", {root: true});
        context.dispatch("Backend/fetch_all", "branch", {root: true});
        context.dispatch("Backend/fetch_all", "shift", {root: true});
    },
    socket_messages(context, payload) {
        context.commit("socket_messages", payload);
        let myBranchName = false;
        if(context.rootState.Profile && context.rootState.Profile.all.profile.get_branch && context.rootState.Profile.all.profile.get_branch[0]){
            myBranchName = context.rootState.Profile.all.profile.get_branch[0].branch_name; 
        }
        
        // let userRole = context.rootState.Profile.all.profile.profile_type;
        if (payload.action == 'cataloque' && context.state.all.cacheTriggers.catalogue) {
            //console.log("11");
            // console.log('update catalogue');
            context.dispatch("fetch_catalogue")
            //context.dispatch("fetch_all", "meta_key");
        }
        if (payload.action == 'workflow' && context.state.all.cacheTriggers.workflow) {
            //console.log("12");
            //console.log('update workflow');
            context.dispatch("fetch_workflow")
        }
        if (payload.action == 'user' && payload.group == myBranchName && context.state.all.cacheTriggers.users) {
            //console.log("13");
            //console.log('update staff');
            context.dispatch("fetch_user")
        }

        if (payload.action == 'ui' && context.state.all.cacheTriggers.ui) {
           // console.log("14");
            // console.log('update ui');
            context.dispatch("fetch_ui")
        }
        if (payload.action && payload.action == 'organization' && payload.group == myBranchName && context.state.all.cacheTriggers.organization) {
           // console.log("15");
            context.dispatch("fetch_organization")
        }
    }
};

const getters = {
    all: (state) => state.all,
    warehouse: (state) => state.all.warehouse,
    stock: (state) => state.all.stock,
    order: (state) => state.all.order,
    payment_processor: (state) => state.all.payment_processor,
    invoice: (state) => state.all.invoice,
    line_items: (state) => state.all.line_items,
    organization: (state) => state.all.organization,
    branch: (state) => state.all.branch,
    inventory: (state) => state.all.inventory,
    catalogue: (state) => state.all.catalogue,
    addresses: (state) => state.all.addresses,
    profiler: (state) => state.all.profiler,
    support: (state) => state.all.support,
    refund: (state) => state.all.refund,
    theme: (state) => state.all.theme,
    shift: (state) => state.all.shift,
    meta_key: (state) => state.all.meta_key,
    socket_status: (state) => state.socket_status,
    socket_messages: (state) => state.socket_messages,
    rt_catalogue: (state) => state.all.cacheTriggers.catalogue,
    rt_workflow: (state) => state.all.cacheTriggers.workflow,
    rt_users: (state) => state.all.cacheTriggers.users,
    rt_organization: (state) => state.all.cacheTriggers.organization,
    rt_ui: (state) => state.all.cacheTriggers.ui,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
