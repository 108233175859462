import api from "../../api/api";
//axios.defaults.withCredentials = true;
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const state = {
    all: {},
    ticket: {},
    ticket_list: [],
    ticket_timeline: [],
    ticket_entry: [],
};

const mutations = {
    UPDATE_TICKET(state, ticket) {
        state.ticket = ticket;
    },
    UPDATE_TICKET_LIST(state, ticket_list) {
        state.ticket_list = ticket_list;
    },
    UPDATE_TICKET_TIMELINE(state, ticket_timeline) {
        //console.log("update the support tickets");
        state.ticket_timeline = [];
        if (ticket_timeline != false) {
            state.ticket_timeline = ticket_timeline;
        }
    },
    UPDATE_TICKET_ENTRY(state, ticket_entry) {
        state.ticket_entry = ticket_entry;
    },
};

const actions = {
    async api_ticket(context, ticket) {
        let payload = {};

        //the api call
        await api.instance
            .get(
                "/api/support/" +
                context.rootState.Profile.all.profile.organization_id +
                "/" +
                context.rootState.Profile.all.profile.branch_id +
                "/" +
                ticket
            )
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = {context: false, widgets: false};
                } else {
                    payload = res.data;
                }
                context.commit("UPDATE_TICKET", payload);
            });
    },
   async api_ticket_list(context) {
        let payload = {};
        await api.instance
            .get(
                "/api/support/" +
                context.rootState.Profile.all.profile.organization_id +
                "/" +
                context.rootState.Profile.all.profile.branch_id
            )
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = {context: false, widgets: false};
                } else {
                    payload = res.data;
                }
                context.commit("UPDATE_TICKET_LIST", payload);
            });
    },
    async api_ticket_timeline(context, ticket) {
        let payload = {};
        await api.instance
            .get(
                "/api/support/" +
                context.rootState.Profile.all.profile.branch_id +
                "/chat_history/" +
                ticket
            )
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = false;
                } else {
                    payload = res.data;
                }
                context.commit("UPDATE_TICKET_TIMELINE", payload);
            });
    },
    async api_ticket_entry(context) {
        let payload = {};
        await api.instance
            .post(
                "/api/support/" +
                context.rootState.Profile.all.profile.organization_id +
                "/" +
                context.rootState.Profile.all.profile.branch_id,
                {ticket: context.state.ticket_entry}
            )
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = {context: false, widgets: false};
                } else {
                    payload = res.data;
                }
                context.commit("UPDATE_TICKET_ENTRY", payload);
            });
    },
};

const getters = {
    ticket: (state) => state.ticket,
    ticket_list: (state) => state.ticket_list,
    ticket_timeline: (state) => state.ticket_timeline,
    ticket_entry: (state) => state.ticket_entry,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
