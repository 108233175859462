import api from "../../api/api";
//axios.defaults.withCredentials = true;
//axios.defaults.withCredentials = true;
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const state = {
    all: {
        token: false,
        token_user: false,
        token_date: null,
        profile: false,
        user: false,
        address: false,
    },
    second_user: false,
    date_formats: {},
    profileList: [],
    customers: false,
    companies: false,
    internal_staff: false,
    authenticated: false,
    accessToken: "",
    user: {},
};

//commits
const mutations = {
    customers(state, payload) {
        state.customers = payload;
    },
    companies(state, payload) {
        state.companies = payload;
    },
    internal_staff(state, payload) {
        state.internal_staff = payload;
    },
    UPDATE_PROFILE(state, payload) {
        state.all.profile = payload;
    },
    UPDATE_PROFILE_LIST(state, payload) {
        state.profileList = payload;
    },
    UPDATE_USER(state, payload) {
        state.all.user = payload;
    },
    UPDATE_TOKEN(state, payload) {
        state.all.token = payload.token;
        state.all.token_user = payload.user;
        localStorage.setItem("token", payload.token);
        localStorage.setItem("token_user", payload.user);
        localStorage.setItem("token_created", Date.now());
        state.all.token_date = Date.now();
    },
    LOGOUT(state) {
        state.all = {
            token: false,
            token_user: false,
            token_date: null,
            profile: false,
            user: false,
            address: false,
        }
    },
    UPDATE_ADDRESS(state, address) {
        state.all.address = address;
    },
    UPDATE_SECOND_USER(state, user) {
        state.second_user = user;
    },
    SET_AUTHENTICATED(state, payload){
        state.authenticated = payload
    },
    SET_USER(state, payload){
        state.user = payload
    },
    SET_ACCESS_TOKEN(state, payload){
        state.accessToken = payload
    },
};

//dispatches
const actions = {
    async login(context, credentials){
        await api.instance.post('/api/auth/login', credentials)
            .then(async (response) => {
                if (Object.prototype.hasOwnProperty.call(response, 'data')) {
                    if(response.data.status.toLowerCase() === "success"){
                        localStorage.setItem('token', response.data.data.token);
                        context.commit('SET_AUTHENTICATED', true);
                        context.commit('SET_ACCESS_TOKEN', response.data.data.token);
                        return await context.dispatch('me');
                    }
                }
            })
    },
    async me({ commit }){
        return await api.instance.get('/api/me').then(response => {
            commit('SET_USER', response.data);
            localStorage.removeItem('timeout_message');
            commit('SET_AUTHENTICATED', false);
            commit('SET_USER', null);
        });
    },
    async logout({ commit }){
        await api.instance.post('/api/auth/logout')
            .then(() => {
                localStorage.setItem('dialogShow',true)
                localStorage.removeItem('token');
                localStorage.removeItem('access_time_to');
                localStorage.removeItem('access_time_from');
                commit('SET_AUTHENTICATED', false);
                commit('SET_ACCESS_TOKEN', "");
                commit('SET_USER', null);
                commit('LOGOUT', null);
            })
            .catch((err) => {
                console.log(err)
            });
    },
    async fetch_User(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/me").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_USER", payload);
            context.dispatch("fetch_Profile");
        })
        .catch(function (error) {
            if (error.response.status == 401) {
                // window.location.replace('/auth');
            }
        });
    },
    async fetch_customers(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/profiler/fetch_customers").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("customers", payload);
        });
    },
    async fetch_internal_staff(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/profiler/fetch_internal_staff").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("internal_staff", payload);
        });
    },
    async fetch_companies(context) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get("/api/profiler/fetch_companies").then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = false;
            } else {
                payload = res.data;
            }
            context.commit("companies", payload);
        });
    },
    async fetch_Profile(context, id = false) {
        // fetch page layouts and their widget patterns
        let second_user = true;
        if (id == false) {
            second_user = false;
            id = context.rootState.Profile.all.user.profiler_id;
        }
        let payload = {};
        await api.instance.get("/api/profiler/" + id).then((res) => {
            
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            if (!second_user) {
                localStorage.setItem("access_time_to", payload.access_time_to);
                localStorage.setItem("access_time_from", payload.access_time_from);
                context.commit("UPDATE_PROFILE", payload);
            } else {
                context.commit("UPDATE_SECOND_USER", payload);
            }
        });
    },
    async fetch_Profile_list(context, profile_type) {
        // fetch page layouts and their widget patterns
        let payload = {};
        await api.instance.get(
                "/api/profiler/get_list/" +
                context.rootState.Profile.all.profile.organization_id +
                "/" +
                context.rootState.Profile.all.profile.branch_id +
                "/" +
                profile_type
            )
            .then((res) => {
                if (!res.data || res.data.length == 0) {
                    payload = {data: false};
                } else {
                    payload = res.data;
                }
                context.commit("UPDATE_PROFILE_LIST", payload);
            });
    },
    async fetch_address(context, address) {
        let payload = {};
        await api.instance.get("/api/address/" + address.id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_ADDRESS", payload);
        });
    },
    async update_address(context, address) {
        let payload = {};
        await api.instance.put("/api/address/" + address.id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_ADDRESS", payload);
        });
    },
    async update_profile(context, address) {
        let payload = {};
        await api.instance.get("/api/profiler/" + address.id).then((res) => {
            if (!res.data || res.data.length == 0) {
                payload = {data: false};
            } else {
                payload = res.data;
            }
            context.commit("UPDATE_PROFILE", payload);
            localStorage.setItem("access_time_to", payload.access_time_to);
            localStorage.setItem("access_time_from", payload.access_time_from);
        });
    },
};

const getters = {
    getProfile: (state) => state.all,
    getProfileList: (state) => state.profileList,
    getAddress: (state) => state.all.address,
    getSecondProfile: (state) => state.second_user,
    customers: (state) => state.customers,
    companies: (state) => state.companies,
    internal_staff: (state) => state.internal_staff,
    authenticated(state){
        state.authenticated
    },
    user(state){
        state.user
    },
    accessToken(state){
        state.accessToken
    },
    accessTimeTo(state){
        state.all.profile.access_time_to
    },
    accessTimeFrom(state){
        state.all.profile.access_time_from
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
