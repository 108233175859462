import api from "../../api/api";
//axios.defaults.withCredentials = true;
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const state = {
    part_sales_history: false,
    payment_history: false,
    stock_history: false,
    stock_history_by_inventory: false,
    location_history: false,
    location_history_by_inventory: false,
    user_history: false,
    user_sales_history: false,
    entity_history: false,
    entity_sales_history: false,
    reporting_branches: false,
    reporting_customers: false,
    inventory_history: false,
    inventory_history_refunds: false,
    inventory_history_sold: false,
    user_sales_history_client: false,
    sales_by_sales_person: false,
    orders_advanced: false,
    pos_report_by_customer: false,
    parts_sales_report: false,
    stock_report_sales: null,
};

const mutations = {
    payment_history(state, payload) {
        state.payment_history = payload;
    },
    stock_history(state, payload) {
        state.stock_history = payload;
    },
    stock_history_by_inventory(state, payload) {
        state.stock_history_by_inventory = payload;
    },
    location_history(state, payload) {
        state.location_history = payload;
    },
    location_history_by_inventory(state, payload) {
        state.location_history_by_inventory = payload;
    },
    user_history(state, payload) {
        state.user_history = payload;
    },
    user_sales_history(state, payload) {
        state.user_sales_history = payload;
    },
    entity_history(state, payload) {
        state.entity_history = payload;
    },
    entity_sales_history(state, payload) {
        state.entity_sales_history = payload;
    },
    reporting_branches(state, payload) {
        state.reporting_branches = payload
    },
    reporting_customers(state, payload) {
        state.reporting_customers = payload
    },
    inventory_history(state, payload) {
        state.inventory_history = payload;
    },
    inventory_history_refunds(state, payload) {
        state.inventory_history_refunds = payload;
    },
    inventory_history_sold(state, payload) {
        state.inventory_history_sold = payload;
    },
    user_sales_history_client(state, payload) {
        state.user_sales_history_client = payload;
    },
    part_sales_history(state, payload) {
        state.part_sales_history = payload;
    },
    sales_by_sales_person(state, payload) {
        state.sales_by_sales_person = payload;
    },
    parts_sales_report(state, payload) {
        state.parts_sales_report = payload
    },
    orders_advanced(state, payload) {
        //force data type to change for vue to register shift
        state.orders_advanced = false;
        state.orders_advanced = payload;
    },

    pos_report_by_customer(state, payload) {
        //force data type to change for vue to register shift
        state.pos_report_by_customer = false;
        state.pos_report_by_customer = payload;
    },

    stock_report_sales(state, payload){
        state.stock_report_sales = payload
    }
};

const actions = {
    async payment_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/payment_history", payload).then((res) => {
            context.commit("payment_history", res.data);
        });
    },
    async stock_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/stock_history", payload).then((res) => {
            context.commit("stock_history", res.data);
        });
    },
    async stock_history_by_inventory(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/stock_history_by_inventory", payload)
            .then((res) => {
                context.commit("stock_history_by_inventory", res.data);
            });
    },
    async location_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/location_history", payload).then((res) => {
            context.commit("location_history", res.data);
        });
    },
    async location_history_by_inventory(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/location_history_by_inventory", payload)
            .then((res) => {
                context.commit("location_history_by_inventory", res.data);
            });
    },
    async user_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/user_history", payload).then((res) => {
            context.commit("user_history", res.data);
        });
    },
    async user_sales_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/user_sales_history", payload).then((res) => {
            context.commit("user_sales_history", res.data);
        });
    },
    async entity_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/entity_history", payload).then((res) => {
            context.commit("entity_history", res.data);
        });
    },
    async entity_sales_history(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/entity_sales_history", payload)
            .then((res) => {
                context.commit("entity_sales_history", res.data);
            });
    },
    async reporting_branches(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/reporting_branches", payload)
            .then((res) => {
                context.commit("reporting_branches", res.data);
            });
    },
    async reporting_customers(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/reporting_customers", payload)
            .then((res) => {
                context.commit("reporting_customers", res.data);
            });
    },
    async inventory_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/inventory_history", payload).then((res) => {
            context.commit("inventory_history", res.data);
        });
    },
    async inventory_history_refunds(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/inventory_history_refunds", payload)
            .then((res) => {
                context.commit("inventory_history_refunds", res.data);
            });
    },
    async inventory_history_sold(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/inventory_history_sold", payload)
            .then((res) => {
                context.commit("inventory_history_sold", res.data);
            });
    },
    async user_sales_history_client(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/user_sales_history_client", payload)
            .then((res) => {
                context.commit("user_sales_history_client", res.data);
            });
    },
    async part_sales_history(context, payload) {
        // fetch navigation for user level
        await api.instance.post("/api/reporting/part_sales_by_month", payload).then((res) => {
            context.commit("part_sales_history", res.data);
        });
    },
    async sales_by_sales_person(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/sales_by_sales_person", payload)
            .then((res) => {
                context.commit("sales_by_sales_person", res.data);
            });
    },
    async fetch_parts_sales_report(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/parts_sales_report", payload)
            .then((res) => {
                context.commit("parts_sales_report", res.data);
            });
    },
    async export_parts_sales_report(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/reporting/export_parts_sales_report", payload, {
                responseType: "blob"
            })
            .then((res) => {

                let type = "";

                if (payload.exportType === 'excel') {
                    type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                } else if (payload.exportType === 'pdf') {
                    type = 'application/pdf'
                }

                const fileURL = window.URL.createObjectURL(new Blob([res.data], {
                    type: type,
                }));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;

                let extension = "";

                if (payload.exportType === 'excel') {
                    extension = '.xlsx';
                } else if (payload.exportType === 'pdf') {
                    extension = '.pdf';
                } else if (payload.exportType === 'html') {
                    extension = '.html';
                } else {
                    extension = '.csv';
                }

                fileLink.setAttribute('download', `parts_sales_report${extension}`);
                document.body.appendChild(fileLink);
                fileLink.click();

            }).catch(() => {
                alert(`No data was found to export.`)
            });
    },
    async initialize_parts_sales_report(context, payload) {
        await api.instance
            .post("/api/reporting/initialize_parts_sales_report", payload)
            .then((res) => {
                context.commit("parts_sales_report", res.data);
            });
    },
    async orders_advanced(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/order/orders_advanced", payload)
            .then((res) => {
                context.commit("orders_advanced", res.data);
            });
    },
    async pos_report_by_customer(context, payload) {
        // fetch navigation for user level
        await api.instance
            .post("/api/order/pos_report_by_customer", payload)
            .then((res) => {
                context.commit("pos_report_by_customer", res.data);
            });
    },
    async fetch_report_sales(context, payload) {
        await api.instance
            .post("/api/reporting/sales", payload)
            .then((res) => {
                context.commit("stock_report_sales", res.data);
            });
    },
};

const getters = {
    all: (state) => state,
    payment_history: (state) => state.payment_history,
    stock_history: (state) => state.stock_history,
    stock_history_by_inventory: (state) => state.stock_history_by_inventory,
    location_history: (state) => state.location_history,
    location_history_by_inventory: (state) => state.location_history_by_inventory,
    user_history: (state) => state.user_history,
    user_sales_history: (state) => state.user_sales_history,
    entity_history: (state) => state.entity_history,
    entity_sales_history: (state) => state.entity_sales_history,
    reporting_branches: (state) => state.reporting_branches,
    reporting_customers: (state) => state.reporting_customers,
    inventory_history: (state) => state.inventory_history,
    inventory_history_refunds: (state) => state.inventory_history_refunds,
    inventory_history_sold: (state) => state.inventory_history_sold,
    user_sales_history_client: (state) => state.user_sales_history_client,
    part_sales_history: (state) => state.part_sales_history,
    sales_by_sales_person: (state) => state.sales_by_sales_person,
    parts_sales_report: (state) => state.parts_sales_report,
    orders_advanced: (state) => state.orders_advanced,
    pos_report_by_customer: (state) => state.pos_report_by_customer,
    stock_sales_report: (state) => state.stock_report_sales,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
